import React from 'react';
import Masthead from '../../components/Masthead';

const Policies = () => {
	return (
		<div className='about'>
			<Masthead heading1='About' heading2='Policies and Procedures' />

			<div className='banner-list'>
				<h3 className='header spanning'>
					1000 School Safety Readiness Division Overview
				</h3>

				<p style={{ margin: '20px 10px' }}>
					School Safety Readiness (SSR) is a division within the Texas School
					Safety Center (TxSSC) at Texas State University located in San Marcos,
					Texas. For the purpose of the school safety and security registry, the
					SSR Division staff members are responsible for conducting background
					checks for individuals applying to become registered consultants.
				</p>
			</div>

			<h3>
				1100 History of Texas School Safety and Security Consultant Registry
			</h3>

			<p className='left-full'>
				The Texas School Safety and Security Consultant Registry (also referred
				to as the Registry) was established in 2009 under the authority of House
				Bill 1831 in the 81st Legislative Session. This bill mandated that the
				TxSSC establish a registry of persons providing school safety or
				security consulting services in Texas.
			</p>
			<p className='left-full'>
				In 2019, Senate Bill 11, passed in the 86th Legislative Session,
				extended the TxSSC’s responsibility to verify the qualifications and
				ability to provide school safety or security consulting of any person
				seeking to be included on the Registry.
			</p>

			<h3>1110 Purpose</h3>

			<p className='left-full'>
				The Registry exists to serve the safety and security needs of Texas
				schools, including fulfilling the requirements of Texas Education Code
				37.2091. Placement on the Registry is dependent on continued adherence
				to the established policies set forth by the TxSSC.
			</p>

			<h3>
				1111 Authority -{' '}
				<a
					href='https://statutes.capitol.texas.gov/docs/ED/htm/ED.37.htm'
					target='_blank'
					rel='noopener noreferrer'>
					<b> Texas Education Code 37.2091</b>
				</a>
			</h3>

			<div className='callout'>
				<h3>
					<a
						href='https://statutes.capitol.texas.gov/docs/ED/htm/ED.37.htm'
						target='_blank'
						rel='noopener noreferrer'>
						<b> Sec. 37.2091.</b>
					</a>{' '}
					REGISTRY OF PERSONS PROVIDING SCHOOL SAFETY OR SECURITY CONSULTING
					SERVICES.
				</h3>

				<div className='law-ol-list'>
					<ol>
						<li>
							In this section, "school safety or security consulting services"
							includes any service provided to a school district, institution of
							higher education, district facility, or campus by a person
							consisting of advice, information, recommendations, data
							collection, or safety and security audit services relevant to
							school safety and security, regardless of whether the person is
							paid for those services.
						</li>
						<li>
							The center shall establish a registry of persons providing school
							safety or security consulting services in this state.
							<br />
							(b-1) A school district must confirm that a person is included in
							the registry established under Subsection (b) before the district
							may engage the person to provide school safety or security
							consulting services to the district.
						</li>
						<li>
							Each person providing school safety or security consulting
							services in this state shall register with the center in
							accordance with requirements established by the center. The
							requirements must include provisions requiring a person
							registering with the center to provide information regarding:
							<ol type='1' className='numerical'>
								<li>
									the person's background, education, and experience that are
									relevant to the person's ability to provide knowledgeable and
									effective school safety or security consulting services; and
								</li>
								<li>
									any complaints or pending litigation relating to the person's
									provision of school safety or security consulting services.
								</li>
							</ol>
						</li>
						<li>
							The center shall verify the information provided by a person under
							Subsection (c) to confirm the person's qualifications and ability
							to provide school safety or security consulting services before
							adding the person to the registry.
						</li>
						<li>
							The center shall include information regarding the registry,
							including the number of persons registered and the general degree
							of school safety or security experience possessed by those
							persons, in the biennial report required by Section{' '}
							<a
								href='http://www.statutes.legis.state.tx.us/GetStatute.aspx?Code=ED&Value=37.216'
								alt='section 37.216 link'>
								37.216
							</a>
							.
						</li>
					</ol>
				</div>
				<p className='left-full'>
					<b>Added by:</b>
				</p>
				<p className='left-full'>
					Acts 2009, 81st Leg., R.S., Ch. 1280 (H.B.{' '}
					<a
						href='http://www.legis.state.tx.us/tlodocs/81R/billtext/html/HB01831F.HTM'
						target='_blank'
						rel='noopener noreferrer'
						alt='H.B. 1831 link'>
						1831
					</a>
					), Sec. 6.09, eff. September 1, 2009.
				</p>
				<p className='left-full'>
					<b>Amended by:</b>
				</p>
				<p className='left-full'>
					Acts 2019, 86th Leg., R.S., Ch. 464 (S.B.{' '}
					<a
						href='http://www.legis.state.tx.us/tlodocs/86R/billtext/html/SB00011F.HTM'
						target='_blank'
						rel='noopener noreferrer'
						alt='S.B. 11 link'>
						11
					</a>
					), Sec. 16, eff. June 6, 2019.
				</p>
				<p className='left-full'>
					Acts 2023, 88th Leg., R.S., Ch. 896 (H.B{' '}
					<a
						href='https://capitol.texas.gov/tlodocs/88R/billtext/html/HB00003F.HTM'
						target='_blank'
						rel='noopener noreferrer'
						alt='hb 3 link'>
						3
					</a>
					), Sec. 18, eff. September 1, 2023.
				</p>
			</div>

			<h3>1112 Access to Criminal History Record Information</h3>

			<p className='left-full'>
				Government Code 411.14055 gives authority to the TxSSC to obtain
				criminal history record information maintained by the Texas Department
				of Public Safety (DPS) that relates to a person who is registering with
				the TxSSC to provide school safety or security consulting services under
				section 37.2091 of the Texas Education Code.
			</p>

			<h3>1200 Division of Responsibilities</h3>

			<p className='left-full'>
				All stakeholders affected by this policy, as defined in this section,
				are responsible for understanding the policy and contacting the TxSSC
				about questions pertaining to background checks.
			</p>

			<h3>1210 School Districts</h3>

			<p className='left-full'>
				School districts are expected to follow their own policy and procedures
				and any other state guidelines under Chapter 22 of the Texas Education
				Code as it relates to utilizing contractors to provide services for
				their school district.
			</p>
			<p className='left-full'>
				TxSSC does not endorse any consultant listed in the Registry.
			</p>

			<h3>1211 Filing Complaints</h3>

			<p className='left-full'>
				School districts should notify the TxSSC if they become aware that a
				registered consultant violated any sections of this policy. Complaints
				should be submitted via the established complaint process on the TxSSC
				Registry website.
			</p>

			<h3>1220 School Safety and Security Consultants </h3>

			<p className='left-full'>
				Individuals seeking to become a registered school safety or security
				consultant to perform consulting services must:
			</p>
			<ul>
				<li>
					Be at least 18 years of age, as defined by Civil Practice and Remedies
					Code 129.001
				</li>
				<li>
					Complete the background check process through the Registry software.
				</li>
			</ul>

			<h3>1221 Registry Misrepresentation</h3>

			<p className='left-full'>
				Misrepresentation of the TxSSC is prohibited. Consultants should not
				claim affiliation with or endorsement by the TxSSC. Furthermore,
				consultants shall not display or advertise the TxSSC name, logo, or
				likeness without express written consent from the Director of the TxSSC.
				Misrepresentation does not preclude a registered consultant from
				advertising their status, including disciplines, on the Registry.
			</p>

			<h3>1222 Consultant E-Mail Addresses and Physical Addresses</h3>

			<p className='left-full'>
				Consultants must provide and maintain current email and physical
				addresses on their registry profile. If TxSSC is unable to contact a
				registered consultant or they become aware that a consultant’s email
				and/or physical address is not current the consultant will be placed on
				administrative suspension.
			</p>

			<h3>1223 Reporting New Criminal Incidents and Other Disqualifiers</h3>

			<p className='left-full'>
				Registered consultants are required to report any new disqualifying
				incident, including criminal charges, administrative sanctions, and
				litigation. For a list of disqualifiers, see Appendix-1 and Appendix-2.
			</p>
			<p className='left-full'>
				Registered consultants must report the incident(s) within three business
				days to TxSSC at{' '}
				<a href='mailto:txssc_registry@txstate.edu'>
					txssc_registry@txstate.edu
				</a>
				. Upon notification by the consultant, the Texas Department of Public
				Safety, or other applicable state or federal agency, SSR staff will
				review the Summary of Disqualifiers and Non-Criminal History
				Disqualifier Chart to determine the consultant's status on the Registry.
			</p>

			<div className='banner-list'>
				<h3 className='header spanning'>
					2000 Background Checks for School Safety and Security Registry
					Applicants
				</h3>

				<p style={{ margin: '20px 10px' }}>
					TxSSC staff conducts background checks on individuals who are seeking
					to become registered school safety or security consultants in the
					state of Texas. Placement on the Registry is contingent on the results
					of a background check.
				</p>
				<p style={{ margin: '20px 10px' }}>
					Although the TxSSC determines whether an individual may or may not be
					listed on the Registry, the TxSSC does not endorse any consultant on
					the Registry. School districts are responsible for conducting their
					own due diligence before contracting with a consultant.
				</p>
			</div>

			<h3>
				2100 Types of Background Checks Required for School Safety and Security
				Registry Consultants
			</h3>

			<p className='left-full'>
				A background check will always include the following:
			</p>

			<ul>
				<li>
					<strong>National Criminal History Check: </strong> A fingerprint-based
					search of both the DPS database of crimes committed in Texas and the
					Federal Bureau of Investigation (FBI) database of crimes committed
					within the United States. Results consist of U.S. based arrests and
					dispositions obtained from fingerprint submissions, disposition
					reports, and other information submitted by agencies having criminal
					justice responsibilities. TxSSC will check results against the
					criminal history disqualifiers referenced in Appendix-1. There is an
					applicant fee involved, paid to the state identified vendor.
				</li>
				<li>
					<strong>Sex Offender Registry Check:</strong> A search of the National
					Sex Offender Public Website (NSPOW) and the Texas Department of Public
					Safety Website, which contains all information provided to Texas local
					law enforcement authorities by sex offenders required to register.
				</li>
				<li>
					<strong>TEA Do Not Hire Registry Check:</strong> A search of the Texas
					Education Agency (TEA) Do Not Hire Registry, which includes
					individuals without educator certificates who are not eligible for
					hire in Texas public school based on misconduct or criminal history.
				</li>
				<li>
					<strong>SBEC Educator Certificate Search Check:</strong> A search of
					the State Board of Educator Certification (SBEC) website. The SBEC
					Educator Certificate Search is a public tool to find the official
					record of the educator’s certification status. Negative information
					will prompt a discussion with TEA Educator Investigations for
					certification status context.
				</li>
			</ul>

			<h3>2200 Alternate Background Checks</h3>

			<p className='left-full'>
				All individuals seeking inclusion onto the Registry are required to
				undergo the background check process outlined in this policy. Previous
				background checks conducted for other processes will not be considered.
			</p>

			<h3>2300 School Safety and Security Consultant Disciplines</h3>

			<p className='left-full'>
				Individuals applying to become a school safety and security consultant
				must register under at least one of the following disciplines:
			</p>

			<ul>
				<li>Audit Consultant</li>
				<li>Multi-Hazard Emergency Operations Plan (EOP) Consultant</li>
				<li>School Behavioral Threat Assessment (SBTA) Consultant</li>
				<li>Other Safety and Security Discipline Consultant</li>
			</ul>

			<p className='left-full'>
				Individuals seeking to provide services under one of the disciplines
				referenced above must meet all qualifications and training requirements
				for that discipline. If an applicant is seeking to provide services
				under multiple disciplines, they must meet the qualifications and
				training requirements for each discipline. For a description of each
				discipline and a complete list of the qualifications and training
				requirements please visit the Registry website.
			</p>

			<h3>2400 Registry Profile Updates and Background Check Renewals</h3>

			<p className='left-full'>
				Registered consultants are required to update or verify that the
				information in their profile is accurate. Registered consultants will
				receive a renewal notification via the Registry software every{' '}
				<strong>
					<u>2 years</u>
				</strong>
				. The notification will prompt the consultant to submit a renewal form
				within the Registry software.
			</p>

			<p className='left-full'>
				If the results of the renewal check reveal disqualifying criminal
				history or other disqualifying history, the individual will be suspended
				from the Registry. If a registered consultant fails to respond to
				requests for more information within the allotted time they will be
				suspended from the Registry.
			</p>

			<h3>2500 Background Check Determinations</h3>

			<p className='left-full'>
				TxSSC staff make determinations to approve or deny an applicant based on
				their background check results.
			</p>

			<p className='left-full'>The initial determination is based on:</p>

			<ul>
				<li>
					<a href='#appendix-1' alt='link to appendix 1 table'>
						<strong>Appendix-1:</strong>
					</a>{' '}
					Summary of Disqualifiers for TxSSC School Safety and Security
					Consultant Registry Chart
				</li>
				<li>
					<a href='#appendix-2' alt='link to appendix 2 table'>
						<strong>Appendix-2:</strong>
					</a>{' '}
					TxSSC Non-Criminal History Disqualifiers Chart
				</li>
			</ul>

			<p className='left-full'>The charts indicate:</p>

			<ul>
				<li>Criminal convictions that are indefinite disqualifiers</li>
				<li>
					Criminal convictions that are disqualifiers for specified timeframes
				</li>
				<li>Non-criminal disqualifiers </li>
			</ul>

			<p className='left-full'>
				{' '}
				Under this policy, criminal convictions also include:
			</p>

			<ul>
				<li>Deferred adjudications</li>
				<li>Convictions for similar crimes in other states</li>
			</ul>

			<h3>2510 Background Check Decisions: Approved</h3>

			<p className='left-full'>
				If an applicant has no history of any criminal convictions or other
				disqualifiers listed on TxSSC Criminal History Disqualifiers Chart or
				TxSSC Non-Criminal History Disqualifiers Chart the applicant will be
				notified of the approval of the general application step via the
				Registry software. Once the general application is approved, a document
				with links for discipline registration will be sent to the applicant
				from{' '}
				<a href='mailto:txssc_registry@txstate.edu'>
					txssc_registry@txstate.edu
				</a>
				.
			</p>

			<h3>2520 Background Check Decisions: Denied</h3>

			<p className='left-full'>
				If an applicant has a history of criminal convictions, active warrants,
				or other disqualifiers that is identified as a permanent disqualifier or
				the conviction occurred during the disqualifying period, the subject of
				the background check will be notified of the denial via the Registry
				software.
			</p>

			<ul style={{ listStyle: 'none' }}>
				<li>
					<strong>2521 Administrative Denials</strong>
					<p>
						Administrative denials are typically reserved for situations in
						which an applicant fails to provide requested or required
						documentation to show proof of qualifications or training
						requirements.
					</p>
					<p>
						Applicants denied for an administrative reason may immediately
						reapply to the Registry and provide the required documentation
						without requesting an appeal.
					</p>
				</li>
				<li>
					<strong>2522 Substantive Denials</strong>
					<p>
						Substantive denials are typically reserved for more serious
						violations of this policy which may include being convicted for a
						disqualifying criminal offense, being listed as a sex offender in
						Texas or any other state or being listed on the TEA Do Not Hire
						Registry. If an applicant is denied for a substantive reason they
						must appeal for reconsideration.
					</p>
					<p>
						TxSSC reserves the right to deny an applicant for other serious
						issues identified during the background check process that are not
						listed above.
					</p>
				</li>
			</ul>

			<h3>2600 Incomplete Background Checks</h3>

			<p className='left-full'>
				A background check is considered incomplete if an applicant does not
				provide requested information or documents within ninety{' '}
				<strong>(90) calendar days</strong> of the initial submission date.
			</p>
			<p className='left-full'>
				If a background check remains incomplete beyond the above time limit,
				the background check process will be closed, and the individual will not
				be placed on the Registry.
			</p>
			<p className='left-full'>
				After being considered incomplete, the background check will be closed,
				and the applicant must submit a new background check request to restart
				the process once the required documentation is available.
			</p>
			<div className='banner-list'>
				<h3 className='header spanning'>
					3000 Applicant Appeal Process for Substantive Denials
				</h3>

				<p style={{ margin: '20px 10px' }}>
					Registry applicants denied placement on the Registry due to their
					background check have a right to appeal the decision made by the
					TxSSC.
				</p>
			</div>

			<h3>3100 First Appeal: Substantive Denials</h3>

			<p className='left-full'>
				An applicant denied for substantive reasons may file an appeal within{' '}
				<strong>thirty (30) calendar days</strong> via the Registry software.
				The initial appeal will be reviewed by the TxSSC Registry Supervisor
				within <strong>fifteen (15) business days</strong> of receipt of the
				appeal. The applicant will receive notification of the appeal
				determination via the Registry software.
			</p>

			<h3>3200 Second Appeal: Substantive Denials</h3>

			<p className='left-full'>
				If the denial was upheld after the initial appeal, an applicant may
				appeal the decision of the TxSSC SSR Supervisor within{' '}
				<strong>thirty (30) calendar days</strong> of the date of the appeal
				denial is sent via the Registry software. The Associate Director of SSR
				will render a final and binding decision concerning the appeal within{' '}
				<strong>fifteen (15) business days</strong> from the date of receipt of
				the appeal.
			</p>

			<h3>3300 Final Denial Period</h3>

			<p className='left-full'>
				If a substantive denial is upheld after both appeals, the applicant is
				not eligible to reapply to the Registry for a minimum of one year, as
				determined by the Director of TxSSC, or the timeframe of the
				disqualifier, if applicable.
			</p>

			<p className='left-full'>
				Failure to appeal within the stated timeframes will be considered a
				final substantive denial.
			</p>

			<div className='banner-list'>
				<h3 className='header spanning'>
					4000 Removal or Suspension from the Registry
				</h3>

				<p style={{ margin: '20px 10px' }}>
					TxSSC reserves the right to suspend or remove a registered consultant
					from the Registry for violating this policy. Violations of the policy
					include, but are not limited to the following: misrepresentation,
					failure to meet requirements for a registered discipline, providing
					false or inaccurate information relating to the background check.
				</p>
				<p style={{ margin: '20px 10px' }}>
					If a decision is made to suspend or remove a registered consultant,
					TxSSC will notify the registered consultant via the Registry software.
				</p>
				<p style={{ margin: '20px 10px' }}>
					A registered consultant may be suspended or removed from the Registry
					for administrative or substantive reasons as described in section 2000
					of this policy.
				</p>
			</div>

			<h3>4100 Appealing Decisions to Suspend a Registered Consultant</h3>

			<p className='left-full'>
				Registered consultants who have been suspended or removed from the
				Registry have the right to request an appeal. Initial decisions to
				suspend a registered consultant are made by the TxSSC Registry
				Supervisor.
			</p>

			<h3>4110 Appeal Process for Administrative Suspensions</h3>

			<p className='left-full'>
				A registered consultant seeking to request an appeal due to being
				suspended from the Registry by the TxSSC Registry Supervisor for a
				violation of this policy may appeal such decision to the Associate
				Director of SSR. The consultant shall submit a request for appeal via
				the Registry software within <strong>thirty (30) calendar days</strong>{' '}
				of the suspension. The Associate Director of SSR shall review the appeal
				and render a decision as to uphold or overturn the suspension within{' '}
				<strong>fifteen (15) business days</strong> from the date of receipt of
				the written appeal request. The decision of the Associate Director of
				SSR shall be final.
			</p>
			<p className='left-full'>
				Failing to correct an administrative deficiency after{' '}
				<strong>six (6) months</strong> of the administrative suspension shall
				be considered a substantive policy violation and will result in a
				substantive suspension.
			</p>

			<h3>4120 First Appeal: Substantive Suspensions</h3>

			<p className='left-full'>
				A registered consultant seeking to request an appeal due to being
				suspended from the Registry for substantive reasons may file an appeal
				within <strong>thirty (30) calendar days</strong> via the Registry
				software. The appeal will be reviewed by a TxSSC SSR Supervisor within{' '}
				<strong>fifteen (15) business days</strong> of receipt of the appeal.
				The registered consultant will receive notification of the appeal
				determination via the Registry software.
			</p>

			<h3>4121 Second Appeal: Substantive Suspensions</h3>

			<p className='left-full'>
				If the suspension was upheld a registered consultant may appeal the
				decision of the TxSSC Registry Supervisor within{' '}
				<strong>thirty (30) calendar days</strong> of the date of the appeal
				denial is sent via the Registry software. The appeal will be reviewed by
				the Associate Director of SSR within{' '}
				<strong>fifteen (15) business days</strong> of the receipt of the
				appeal. The consultant will receive notification of the appeal
				determination via the Registry software.
			</p>

			<h3>4122 Final Appeal Decision: Substantive Suspensions</h3>

			<p className='left-full'>
				If the suspension was upheld by the Associate Director of SSR, a
				registered consultant can request a final appeal to the Director of the
				TxSSC within <strong>thirty (30) calendar days</strong> via the Registry
				software.
			</p>
			<p className='left-full'>
				The Director shall review the appeal and render a decision to uphold or
				overturn the suspension within{' '}
				<strong>fifteen (15) business days</strong> from the date of the appeal
				request. The decision of the Director is final and binding.
			</p>

			<h3>4130 Removal of Consultant from the Registry</h3>

			<p className='left-full'>
				If a registered consultant does not appeal a substantive suspension
				within the specified timeframes they will be removed from the Registry
				and not eligible to reapply for a minimum of one year, as determined by
				the Director of TxSSC, or the timeframe of the disqualifier, if
				applicable.
			</p>
			<p className='left-full'>
				Additionally, if the Director of the TxSSC has denied a substantive
				suspension appeal in 4122 of this policy the registered consultant will
				be removed from the Registry and not eligible to reapply for a minimum
				of one year, as determined by the Director of TxSSC, or the timeframe of
				the disqualifier, if applicable.
			</p>

			<h3 className='centered lg-text' id='appendix-1'>
				Appendix 1-
			</h3>
			<h3 className='centered'>
				Summary of Disqualifiers for the Texas School Safety and Security{' '}
			</h3>
			<h3 className='centered'>Consultant Registry Chart:</h3>

			<table className='appendix'>
				<thead>
					<tr>
						<th>Criminal Punishment</th>
						<th>Disqualifying Period</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className='eighty'>
							Violent and sexually violent felonies, including:
							<ul>
								<li>Offenses listed in Texas CCP 42.12.3(g) or 42A.054</li>
								<li>Offenses denied as sexually violent by Texas CCP 62.001</li>
								<li>Burglary of habitation</li>
							</ul>
						</td>
						<td>Permanent</td>
					</tr>
					<tr>
						<td className='eighty'>
							Certain felonies, including:
							<ul>
								<li>
									Offenses for which a defendant is required to register as a
									sex offender under Texas CCP Chapter 62
								</li>
								<li>
									Texas Penal Code Title V, if the victim of the offense was
									under 18 years of age at the time the offense was committed
								</li>
							</ul>
						</td>
						<td className='twenty'>30 years</td>
					</tr>
					<tr>
						<td>
							Other Felonies, including felony possession and/or distribution of
							controlled substances
						</td>
						<td>10 years</td>
					</tr>
					<tr>
						<td>
							Class A Misdemeanors, including distribution of controlled
							substances{' '}
						</td>
						<td>5 years</td>
					</tr>
					<tr>
						<td>
							Class B Misdemeanors, including distribution of controlled
							substances{' '}
						</td>
						<td>2 years</td>
					</tr>
				</tbody>
			</table>

			<h3 className='centered lg-text' id='appendix-2'>
				Appendix 2-
			</h3>
			<h3 className='centered'>
				TxSSC School Safety and Security Consultant Registry Non-Criminal
				History
			</h3>
			<h3 className='centered'>Disqualifier Chart:</h3>

			<table className='appendix'>
				<tbody>
					<tr>
						<td>
							Status as a sex offender based on search of National Sex Offender
							Registry Search
						</td>
						<td>Automatic Disqualifier</td>
					</tr>
					<tr>
						<td>Texas Education Agency (TEA) Do Not Hire Registry Listing</td>
						<td>Automatic Disqualifier</td>
					</tr>
					<tr>
						<td>Negative SBEC Educator Search Results</td>
						<td>Varies*</td>
					</tr>
				</tbody>
			</table>

			<p className='left-full'>
				Automatic disqualifiers are assessed at the time of the background
				check. If an individual is no longer listed as a sex offender or listed
				on the TEA Do Not Hire List, they can be reconsidered for eligibility.
			</p>
			<p className='left-full'>
				* Indicates information that is not an automatic disqualifier which
				requires additional review to determine an applicant’s eligibility to be
				placed on the Registry.
			</p>
		</div>
	);
};

export default Policies;
