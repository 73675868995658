import React from 'react';
import Kim from '../../assets/images/headshot_kda.jpg';
import Masthead from '../../components/Masthead';

const Team = () => {
	const teamMembers = [
		{
			id: 1,
			name: 'Kimberly Duncan-Ashley',
			division: 'School Safety Readiness',
			title: 'Sr. Program Specialist',
			email: 'kad120@txstate.edu',
			phone: '512-245-5860',
			image: Kim,
		},
	];

	return (
		<div className='contact'>
			<Masthead heading1='Contact Us' heading2='Registry Team' />
			<div className='business-cards'>
				{teamMembers.map((member) => (
					<div key={member.id} className='card'>
						<div className='headshot'>
							<img src={member.image} alt={member.name} />
						</div>
						<div className='team-member-info'>
							<h3 className='team-member-name'>{member.name}</h3>
							<h4 className='team-member-title'>{member.division}</h4>
							<h4 className='team-member-title'>{member.title}</h4>
							<p className='team-member-email'>
								<a href={`mailto:${member.email}`}>
									<i className='fa fa-envelope'></i>
									{member.email}
								</a>
							</p>
							<p className='team-member-phone'>
								<i className='fa fa-phone'></i>
								{member.phone}
							</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Team;
